exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about_us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-contact-form-jsx": () => import("./../../../src/pages/contact_form.jsx" /* webpackChunkName: "component---src-pages-contact-form-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact_us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-pricing-jsx": () => import("./../../../src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */),
  "component---src-pages-services-ci-cd-devops-jsx": () => import("./../../../src/pages/services/ci_cd_devops.jsx" /* webpackChunkName: "component---src-pages-services-ci-cd-devops-jsx" */),
  "component---src-pages-services-cloud-firewall-and-vpn-jsx": () => import("./../../../src/pages/services/cloud_firewall_and_vpn.jsx" /* webpackChunkName: "component---src-pages-services-cloud-firewall-and-vpn-jsx" */),
  "component---src-pages-services-custom-images-jsx": () => import("./../../../src/pages/services/custom_images.jsx" /* webpackChunkName: "component---src-pages-services-custom-images-jsx" */),
  "component---src-pages-services-dedicated-bandwidth-jsx": () => import("./../../../src/pages/services/dedicated_bandwidth.jsx" /* webpackChunkName: "component---src-pages-services-dedicated-bandwidth-jsx" */),
  "component---src-pages-services-flexible-block-storage-jsx": () => import("./../../../src/pages/services/flexible_block_storage.jsx" /* webpackChunkName: "component---src-pages-services-flexible-block-storage-jsx" */),
  "component---src-pages-services-nubi-compute-service-jsx": () => import("./../../../src/pages/services/nubi_compute_service.jsx" /* webpackChunkName: "component---src-pages-services-nubi-compute-service-jsx" */),
  "component---src-pages-services-nubi-migration-service-jsx": () => import("./../../../src/pages/services/nubi_migration_service.jsx" /* webpackChunkName: "component---src-pages-services-nubi-migration-service-jsx" */),
  "component---src-pages-services-nubi-monitoring-service-jsx": () => import("./../../../src/pages/services/nubi_monitoring_service.jsx" /* webpackChunkName: "component---src-pages-services-nubi-monitoring-service-jsx" */),
  "component---src-pages-services-object-storage-service-jsx": () => import("./../../../src/pages/services/object_storage_service.jsx" /* webpackChunkName: "component---src-pages-services-object-storage-service-jsx" */),
  "component---src-pages-services-vpc-jsx": () => import("./../../../src/pages/services/vpc.jsx" /* webpackChunkName: "component---src-pages-services-vpc-jsx" */),
  "component---src-pages-solutions-cloud-pbx-jsx": () => import("./../../../src/pages/solutions/cloud_pbx.jsx" /* webpackChunkName: "component---src-pages-solutions-cloud-pbx-jsx" */),
  "component---src-pages-solutions-colocation-jsx": () => import("./../../../src/pages/solutions/colocation.jsx" /* webpackChunkName: "component---src-pages-solutions-colocation-jsx" */),
  "component---src-pages-solutions-networking-jsx": () => import("./../../../src/pages/solutions/networking.jsx" /* webpackChunkName: "component---src-pages-solutions-networking-jsx" */),
  "component---src-pages-solutions-private-cloud-jsx": () => import("./../../../src/pages/solutions/private_cloud.jsx" /* webpackChunkName: "component---src-pages-solutions-private-cloud-jsx" */),
  "component---src-pages-solutions-sap-jsx": () => import("./../../../src/pages/solutions/sap.jsx" /* webpackChunkName: "component---src-pages-solutions-sap-jsx" */),
  "component---src-pages-soon-jsx": () => import("./../../../src/pages/soon.jsx" /* webpackChunkName: "component---src-pages-soon-jsx" */)
}

